<template>
    <div>
        <div class="mainList">

            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-download" type="primary" @click="exportLog" >导出</el-button>
                        <!-- <el-button size="small" icon="el-icon-document-copy" type="primary" @click="backups" :loading="backupsLoading">备份</el-button> -->
                    </div>

                    <div class="mainList_content_operation-c">
                        <div class="item">
                            <div class="label">登录时间</div>
                            <el-date-picker
                                v-model="createDateinterval"
                                size="small"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                value-format="yyyy-MM-dd"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="item">
                            <div class="label">登录人</div>
                            <el-input placeholder="请输入" size="small" clearable v-model="parameter.condition.loginName" />
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>

                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>

                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" :stripe="elTable.stripe" :border="elTable.border" :data="tableData" tooltip-effect="dark" height="string" @sort-change="sortChange" v-loading="loading" header-cell-class-name="meterHeader">

                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>

                        <el-table-column v-for="n in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>

                    </el-table>
                    <el-pagination
                        @current-change="pageChangeHandler"
                        @size-change="handleSizeChange"
                        :current-page="parameter.pageNumber"
                        :page-size="parameter.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
import {exportLog} from "@/api/controller/admin/loginLog";
import {SERVICEADDRESS} from '@/config'
export default {
    components: {
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/loginLog'].findPage,
            },
            tableColumnData:[
                {prop:'createDate',label:'登录时间',isShow:true,sortable:false,fixed:false,},
                {prop:'loginName',label:'登录人',isShow:true,sortable:false,fixed:false,},
            ],
            createDateinterval:'',
            backupsLoading: false,

        };
    },
    watch:{
        createDateinterval: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                if(newVal){
                    this.parameter.condition.startTime = newVal[0];
                    this.parameter.condition.endTime = newVal[1];
                }else {
                    this.parameter.condition.startTime = '';
                    this.parameter.condition.endTime = '';
                }
            }
        },
    },
    created() {
        this.getData();
    },
    methods: {
        exportLog(){
            window.open(`${SERVICEADDRESS[process.env.NODE_ENV].admin}/admin/loginLog/export`)
        },
        backups(){
            this.backupsLoading = false;
            this.$api['admin/loginLog'].backups().then(res => {
                this.backupsLoading = false;
                this.$message.success("操作成功！");
            }).catch(err => {
                console.log(err);
                this.backupsLoading = false;
            });
        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
